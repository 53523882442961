<template>
    <div>
        <search-header></search-header>
        <div class="container p-0">
            <div class="row p-0">
                <div class="col-12 my-4 pt-4 pb-5 d-flex justify-content-center align-items-center">
                    <div class="d-flex flex-column justify-content-center align-items-center" >
                        <img class="image" src="~@/assets/img/404.png" />
                        <div class="d-flex mt-3 text-center h4">哎呀，您访问的页面不存在！</div>
                        <div class="d-flex f-14 text-center text-gray-777">您输入的网址不正确，或者该网址不存在。</div>
                        <div class="d-flex f-14 mt-1 justify-content-between align-items-center">
                            <div class="text-theme">{{countDown}}秒后跳转到首页</div>
                            <router-link to="/" class="ml-4">返回首页</router-link>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <map-footer></map-footer>
    </div>
</template>

<script>
import MapFooter from '../../components/footer/map-footer.vue'
import searchHeader from '../../components/header/search-header.vue'

export default {
  components: { searchHeader },
  data() {
      return {
          countDown: 10,
          timer: null
      }
  },
  mounted() {
    this.timer = setInterval(()=>{
        if(this.countDown > 0) {
            this.countDown --;
        } else {
            clearInterval(this.timer);
            this.$router.replace({
                path: '/'
            })
        }
    },1000);
  }
}
</script>

<style scoped>
    .image {
        width: 310px; 
        height: 165px;
    }
</style>
